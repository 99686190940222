<template>
	<div class="landing">
		<p class="line">
			<span class="tag">{{`<!DOCTYPE NTUST>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`<NTUST>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`<HEAD>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`<TITLE>`}}</span>
			<span class="inner">HELLO DEAR!</span>
			<span class="tag">{{`</TITLE>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`<BODY>`}}</span>
		</p>
		<p v-if="media === 'desktop'" class="line">
			<span ref="h1Element" class="tag">{{`<h1>`}}</span>
			<span class="inner">WELCOME TO THE NATIONAL TAIWAN UNIVERSITY OF</span>
		</p>
		<p v-if="media === 'mobile'" class="line">
			<span ref="h1Element" class="tag">{{`<h1>`}}</span>
			<span class="inner">WELCOME TO THE NATIONAL</span>
		</p>
		<p v-if="media === 'mobile'" class="line" :style="{ marginLeft: `${h1ElementWidth}px`}">
			<span class="inner">TAIWAN UNIVERSITY OF</span>
		</p>
		<p v-if="media === 'desktop'" class="line" :style="{ marginLeft: `${h1ElementWidth}px`}">
			<span class="inner">SCIENCE AND TECHNOLOGY DEPARTMENT OF ARCHITECTURE</span>
		</p>
		<p v-if="media === 'mobile'" class="line" :style="{ marginLeft: `${h1ElementWidth}px`}">
			<span class="inner">SCIENCE AND TECHNOLOGY</span>
		</p>
		<p v-if="media === 'mobile'" class="line" :style="{ marginLeft: `${h1ElementWidth}px`}">
			<span class="inner">DEPARTMENT OF ARCHITECTURE</span>
		</p>
		<p class="line" :style="{ marginLeft: `${h1ElementWidth}px`}">
			<span class="inner">2021 GRADUATION SHOW</span>
			<span class="tag">{{`</h1>`}}</span>
		</p>
		<p v-if="media === 'desktop'" class="line">
			<span class="tag">{{`<P>`}}</span>
			<span class="inner">A FUTURE IS ABOUT TO START</span>
			<span class="tag">{{`</P>`}}</span>
		</p>
		<p v-if="media === 'mobile'" class="line">
			<span ref="pElement" class="tag">{{`<P>`}}</span>
			<span class="inner">A FUTURE IS ABOUT</span>
		</p>
		<p v-if="media === 'mobile'" class="line" :style="{ marginLeft: `${pElementWidth}px`}">
						<span class="inner">TO START</span>
			<span class="tag">{{`</P>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`</BODY>`}}</span>
		</p>
		<p class="line">
			<span class="tag">{{`</NTUST>`}}</span>
		</p>
		<p class="line">
      <!-- to allow autoplay on home page -->
			<router-link to="/home" @click="playMusic">
				<span class="tag">{{`>PRESS`}}</span>
				<span class="cta">{{`RETURN 0;`}}</span>
				<span class="tag">TO START_</span>		
			</router-link>
		</p>
		
		<div class="music">
			<MusicPlayer />
		</div>
	</div>
</template>

<script>
import { ref, inject, computed, onBeforeUnmount, nextTick, onMounted } from "vue";
import { useStore } from 'vuex';
import MusicPlayer from "@/components/molecules/MusicPlayer";
import Music from "../../assets/bg-music.mp3";

export default {
  name: "Landing",
  components: { MusicPlayer },
  setup() {
    const store = useStore()
    const music = ref(new Audio(Music));

    onMounted(() => {
      const lines = document.querySelectorAll('.line');

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];

        line.style.width = line.clientWidth + 8 + 'px';

        if (i + 1 === lines.length) {
          line.className += (` animation-caret animation-delay-${(i)}`);
        } else {
          line.className += (` animation-typing animation-delay-${(i)}`);
        }
      }
    })
    
    const h1Element = ref(null);
    const h1ElementWidth = computed(() => {
      if (!h1Element.value) return 0;
      return h1Element.value.offsetWidth;
    });
    const pElement = ref(null);
    const pElementWidth = computed(() => {
      if (!pElement.value) return 0;
      return pElement.value.offsetWidth;
    });

    const media = inject("media");
    
    onBeforeUnmount(() => {
      stopMusic();
    });
    
    const playMusic = async () => {
      await nextTick()

      await music.value.play();
    };

    const stopMusic = async () => {
      if (music.value?.played) {
        await music.value.pause();

        music.value.currentTime = 0;
      }
    };

    return {
      media,
      h1Element,
      h1ElementWidth,
      pElement,
      pElementWidth,
      playMusic,
      stopMusic
    };
  },
};
</script>

<style lang="scss" scoped>
.landing {
  margin-top: 260px;
  margin-left: 450px;
  display: flex;
  flex-direction: column;
  align-items: self-start;

  @include RWD($mediumLaptop) {
    margin-top: 200px;
    margin-left: 300px;
  }

  @include RWD($tablet) {
    margin: 0;
    padding: 20px 12px;
  }

  p {
    margin: 0 0 3px 0;

    &:last-child {
      margin-top: 50px;
    }

    span {
      font-size: 18px;
      line-height: 29px;
      font-family: "Zpix";
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.6),
        0 0 10px rgba(255, 255, 255, 0.3);

      @include RWD($mediumLaptop) {
        font-size: 16px;
      }

      @include RWD($tablet) {
        font-size: 16px;
      }

      &.tag {
        color: $neon;
      }

      &.inner {
        color: $white;
      }
    }
  }

  a {
    text-decoration: none;
    padding: 0;

    @include RWD($tablet) {
      padding: 20px 0;
    }
  }

  .cta {
    background-color: $neon;
    padding: 3px;
    margin: 0 3px;
    color: $black;
    font-size: 18px;
    line-height: 29px;
    font-family: "Zpix";

    @include RWD($tablet) {
      font-size: 16px;
    }
  }
	
	.music {
		visibility: hidden;
	}

  @keyframes typing {
    from { width: 0; }
    to { visibility: visible; }
  }

  @keyframes caret {
    50% { border-color: rgba(255, 255, 255, 0.4) }
  }

  .animation-typing {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 0.5s steps(8) forwards;

    visibility: hidden;
  }

  @for $i from 0 through 24 {
    .animation-delay-#{$i} {
      animation-delay: #{$i * 0.5}s !important;
    }
  }

  .animation-caret {
    border-right: .25em solid;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 0.5s steps(8) forwards, caret 1s steps(1) infinite;

    visibility: hidden;
  }
}
</style>

